// GLOBLE TAGS
.form-check {
  .form-check-label {
    color: $black;
  }
  .form-check-input {
    border-color: $black;
    &:checked {
      border-color: $ilead-primary;
      background-color: $ilead-primary;
    }
  }
}

.form-label {
  font-weight: $font-weight-normal;
}

.bg-primary {
  background-color: $ilead-primary !important;
}

.flex-1 {
  flex: 1;
}

.btn {
  &.btn-primary {
    background: $ilead-primary !important;
    &:hover,
    &:hover:not(.btn-active),
    &:active,
    &:active:not(.btn-active),
    &:focus,
    &:focus:not(.btn-active) {
      background: darken($ilead-primary, 10%) !important;
    }
    &.active {
      background: $ilead-secondary !important;
      border-color: $ilead-secondary !important;
    }
  }
  &.btn-link {
    &:hover,
    &:hover:not(.btn-active),
    &:active,
    &:active:not(.btn-active),
    &.active {
      color: darken($ilead-primary, 10%) !important;
    }
  }
}

.ilead-text {
  color: $ilead-primary !important;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.post-type {
  color: $post-type-color;
}

// LEFT SIDEBAR MENU
[data-kt-app-layout='dark-sidebar'] {
  .app-sidebar {
    @media screen and (min-width: $breakpoint-desktop) {
      // background-color: $app-sidebar-dark-bg-color;
      background-color: transparent;
      border-right: 0 !important;
    }
    .user-menu,
    .admin-menu {
      background-color: $app-sidebar-dark-bg-color;
      border-radius: 16px;
    }

    .menu {
      padding: 0;
      .menu-item {
        .menu-link {
          &.active {
            background: $ilead-primary;
          }
        }
      }
    }
  }
}

[data-kt-app-sidebar-minimize='on'] .app-sidebar {
  @media screen and (min-width: $breakpoint-desktop) {
    .user-menu,
    .admin-menu {
      width: $app-sidebar-minimize-width - 15px;
      .menu-title {
        white-space: nowrap;
      }
      h5 {
        display: none;
      }
    }

    .full-logo{
      display: none !important;
    }

    .small-logo{
      display: inline-block !important;
    }
  }
}

// GI BI CONTAINER
.gibi-container {
  background: $white;
  padding: 0 1rem 1rem;
  top: 30px;
  .bigi-members {
    // overflow-x: auto;
    // display: flex;
    // flex-wrap: nowrap;
    .member-box {
      width: 107px;
      margin-right: $spacer - 0.375rem;
      background: $white;
      height: 107px;
      position: relative;
      overflow: hidden;
      border-radius: 20px 20px 0px 20px;
      @media screen and (max-width: $breakpoint-mobile) {
        width: 100px;
        height: 100px;
      }
      .member-details {
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        h6 {
          font-size: 14px;
        }
      }
    }
  }
}

// POST FILTER WITH MOBILE VIEW
.post-filter {
  .main-filter {
    width: calc(100% - 54px);
    button {
      font-size: 0.8125rem;
      position: relative;
      border-radius: 0;
      // &::before,
      // &::after {
      //   position: absolute;
      //   content: '';
      //   left: 0;
      //   top: 0;
      //   width: 0;
      //   height: 0;
      //   border: none;
      //   border-left: solid 18px white;
      //   border-bottom: solid 35px transparent;
      //   z-index: 1;
      //   transition: 0.4s;
      // }
      // &::after {
      //   right: 0;
      //   left: inherit;
      //   border-left: solid 18px transparent;
      //   border-bottom: solid 35px white;
      // }
    }
  }
}

// mobile filter
.mobile-filter {
  .filter-scroll-area {
    max-height: 350px;
    overflow-x: auto;
  }
}

// POST VIEW DESIGN
.post-listing {
  .post-card {
    box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.2);
    .top-leader-profile {
      border: 2px solid $post-type-color;
      img {
        border: 2px solid $white;
      }
    }
    .thankx_image {
      img {
        border: 2px solid $ilead-border-color;
      }
    }
    .leaderlink {
      color: $ilead-primary;
    }
    .round-separator {
      background: $ilead-border-color;
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
    }
    .card-footer {
      background: $post-dark-color;
      .nav-item {
        .nav-link {
          max-height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background: darken($post-dark-color, 10%) !important;
            border-radius: 0.25rem;
          }
          &.share-button {
            max-width: calc(100% - 40px);
          }
          i {
            font-size: 20px;
          }
        }
      }

      .nav-item.action,
      .nav-link.action {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 15px;
          background-color: $ilead-border-color;
        }
      }
    }
    .media {
      max-height: 600px;
      overflow: hidden;
    }
    &.hide-post {
      opacity: 0.3;
    }
  }
  .new-post-added {
    position: fixed;
    top: 84px;
    z-index: 9;
    background-color: $ilead-primary !important;
    border-radius: 50px;
    padding: 6px 24px !important;
    text-align: center;
    color: $white;
  }
}

// COMMENT VIEW
#post_comment_view {
  .comment-body {
    .commnet_box_right {
      background-color: $post-dark-color;
      border-radius: 0 8px 8px;
      padding: 1rem;
      .round-separator {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        color: $ilead-separator-color;
      }
    }
  }
}

//ADD POST
.add_post_button {
  position: fixed;
  bottom: 20px;
  border-radius: 100%;
  border: 2px solid $white;
  box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.4);
}

.ilead-default-modal {
  .modal-header {
    background: $ilead-primary;
    * {
      color: white;
    }
  }
  .modal-body {
    &.write-post-wrapper {
      .writepost-btn-group {
        position: relative;
        background: rgba(242, 101, 34, 0.1);
        width: 100%;
        border-radius: 100px;
        overflow: hidden;
        button {
          margin: 0 !important;
          padding: 2px !important;
          background: transparent;
          box-shadow: none;
          color: $black;
          text-transform: inherit;
          font-weight: 500;
          border-radius: 100px;
          height: 44px;
          border: 0;
          &:hover,
          &:active,
          &:focus,
          &.active {
            background: $ilead-primary;
            color: $white;
          }
        }
      }
      .writepost-button {
        padding: 15px 15px !important;
        text-align: center;
        background-color: $white;
        border: 1px solid $ilead-border-color !important;
        border-radius: 15px 15px 0px 15px;
        height: 100%;
        font-size: 14px;
      }
      .tab-content {
        padding-top: 30px;
      }
      .ref-icon img {
        height: 120px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .gratitude-title {
        font-size: 16px;
        border: 1px dotted $ilead-primary;
        border-radius: 5px;
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 15px;
        background-color: rgb($ilead-primary, 0.05);
      }
    }
    .leader_list_scroll_wrapper {
      height: calc(100vh - 380px);
      overflow-y: auto;
    }
    .name-wrapper {
      .name-title {
        font-weight: 500;
      }
      h2 {
        font-size: 16px;
        padding: 10px 10px;
        background-color: #eee;
        border-radius: 5px;
      }
    }
    .whatwentwellbox {
      border: 1px solid $ilead-input-border-color;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
    .goal_box {
      border: 1px solid #e0e0e0;
      border-radius: 8px 8px 8px 0px;
    }
  }
}

// CUSTOM INPUT
.custom-text-area {
  padding: 0px;
  border: none;
  margin-top: 0px;
  resize: none;
  font-size: 14px;
  outline: 0;
  border-radius: 0px;
  padding-top: 7px;
  &:focus {
    outline: 0px;
    box-shadow: none;
  }
}

// CAROUSEL SLIDER
.carousel {
  .carousel-indicators {
    [data-bs-target] {
      width: 12px;
      height: 12px;
      border-radius: 100%;
    }
    &.active {
      background-color: $ilead-primary;
    }
  }
}

// ANIMATION
.go4268192979 {
  background: url('../../../../../public/media/ilead/animation.png') no-repeat !important;
  height: 40px !important;
  width: 40px !important;
}

.go2484888251 {
  background: url('../../../../../public/media/ilead/animation.png') no-repeat !important;
  background-position: -1119px 2px !important;
  height: 40px !important;
  width: 40px !important;
}

// SCROLLING EFFECT CSS
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--wrapper {
  position: relative;
  .react-horizontal-scrolling-menu--arrow-left,
  .react-horizontal-scrolling-menu--arrow-right {
    position: absolute;
    left: -24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    Button {
      width: 44px;
      height: 44px;
      background: $white !important;
      box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.4) !important;
      border-radius: 100%;
    }
    @media screen and (max-width: $breakpoint-mobile) {
      display: none;
    }
  }
  .react-horizontal-scrolling-menu--arrow-right {
    left: inherit;
    right: -24px;
  }
}

.ilead-input {
  height: calc(2.75rem + 8px);
  border: 0px;
  border: 0;
  border-bottom: 1px solid $ilead-input-border-color;
  border-radius: 0.25rem;
  border-radius: 0;
  color: $ilead-secondary;
  font-weight: 400;
}

.input-group {
  .ilead-input-group {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
}

// RESPONSIVE MOBILE
@media screen and (max-width: $breakpoint-mobile) {
  .fs-13 {
    font-size: 11px;
  }

  .fs-14 {
    font-size: 12px;
  }

  .card {
    border-radius: 0;
  }

  .gibi-container {
    top: 0;
    .nav-tabs {
      top: -1px !important;
      position: fixed !important;
      z-index: 99;
    }
  }

  .mobile-noMargin {
    margin-left: 0;
    margin-right: 0;
    .col-lg-6,
    .col-xl-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .auth-bg {
    background-image: none !important;
    .login-card {
      box-shadow: none !important;
    }
  }

  #kt_app_content {
    padding-top: 0 !important;
    #kt_app_content_container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  #kt_app_header {
    border-bottom: 1px solid $ilead-border-color;
  }

  .nav-line-tabs {
    padding-top: 9px !important;
    .nav-item .nav-link {
      padding: 0.5rem 0.625rem;
      margin-right: 0 !important;
      text-align: center;
    }
    // Active & Hover States
    .nav-item .nav-link.active,
    .nav-item.show .nav-link,
    .nav-item .nav-link:hover:not(.disabled) {
      border: 1px solid $ilead-border-color;
      border-bottom: 2px solid $white;
      background: $white;
    }
  }

  .mobile-filter {
    width: 100% !important;
    top: 100px !important;
    transform: inherit !important;
    height: calc(100vh - 100px);
    .bg-white {
      border-radius: 15px 15px 0 0;
    }
    .filter-scroll-area {
      max-height: calc(100vh - 235px);
      overflow: scroll;
    }
    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      top: -100px;
      z-index: -1;
    }
  }

  .post-listing .post-card .card-footer .nav-item .nav-link i {
    font-size: 16px;
  }

  .modal-fullscreen-md-down {
    height: calc(100% - 64px);
    margin-top: 14px;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }

  #ilead_app_mobile_footer {
    position: fixed;
    width: 100%;
    border-top: 1px solid $ilead-border-color;
    background: $white;
    bottom: 0;
    z-index: 9;
    .w-20 {
      width: 20%;
    }
  }

  [data-kt-app-header-fixed-mobile='true'] .app-header {
    z-index: 9;
  }

  .add_post_button {
    bottom: 84px;
    left: inherit;
    right: 20px;
  }

  .ilead-default-modal {
    .modal-fullscreen-md-down {
      margin-top: 0;
      height: 100%;
      border-radius: 0;
    }
    .modal-content {
      background: $ilead-modal-content;
    }
    .modal-body {
      border-radius: 40px 0 0 0;
      background-color: $white;
      width: calc(100% - 15px);
      background: $white;
      margin-left: 15px;
    }
  }
}

// RESPONSIVE 1024PX
@media screen and (min-width: $breakpoint-desktop) {
  .dashboard-wrapper,
  .gibi-container {
    max-width: 600px;
  }
}
