//
// ILEAD Variables
//
$ilead-sidebar-dark-bg-color: #000000;
// $ilead-primary: #f26522;
$ilead-primary: #e3371e;
// $ilead-secondary: #222;
$ilead-secondary: #000000;
$ilead-border-color: #c6c6c6;
// $ilead-input-border-color: #e0e0e0;
$ilead-input-border-color: #b5b5b5;
$ilead-modal-content: #edf1f5;
$ilead-separator-color: #5a5a5a;
$post-dark-color: #f5f5f5;
$post-type-color: #76ab25;
$breakpoint-mobile: 767px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

[data-bs-theme='light'] {
  --bs-text-primary: #e3371e;
  --bs-primary-light: rgba(227, 55, 30, 0.05);
  --bs-link-hover-color: #e3371e;
  --bs-primary: #e3371e;
}
